module.exports = {
    'onlineAssEditor': '在线视频字幕（ASS）编辑器',
    'selectLanguage': '切换语言：',
    'zh': '简体中文',
    'en': '英语',
    'saveAss': '保存字幕文件',
    'remove': '删除',
    'insert record': '插入一行',
    'append record': '追加一行',
    'loadASS': '加载外部 ASS 文件',
    'loadVideo': '加载外部视频文件',
    'loadLocalASS': '加载本地 ASS 文件',
    'login': '登录',
    'source': '源代码',
    'downloadZhihuVideo': '下载知乎视频',
    'why': '本站来历'
}
